


// Fonts + Icons
@import "./webfont/webfont.css";
// $font-head-light-italic: 'Montserrat Light Italic', sans-serif;
// $font-head-regular: 'Montserrat Regular', sans-serif;
// $font-head-bold: 'Montserrat Bold', sans-serif;

$font-extralight: 'poppins extralight', sans-serif;
$font-light: 'poppins light', sans-serif;
$font-book: 'poppins book', sans-serif;
$font-medium: 'poppins medium', sans-serif;
$font-semibold: 'poppins semibold', sans-serif;
$font-bold: 'poppins bold', sans-serif;
$font-extrabold: 'poppins extrabold', sans-serif;
$font-black: 'poppins black', sans-serif;

@import "./icons/style.css";
$font-icon: 'icomoon';


// custom Variables

$rs-red:#de0b25;
$rs-purple:#724293;
$rs-orange:#f19003;
$rs-blue:#036392;
$rs-green:#3baa35;
$rs-pink:#b41781;
$bubble-grey:#efefef;
$bubble-black:#515151;

$black: #000;
$white: #fff;
$light-grey:#ececec;
$grey:#f2f2f2;
$dark-grey:#e0e0e0;


@mixin ease {
  transition:all 0.25s ease-in-out;
}




body, html {
  font-size: 16px;

  @media (max-width:992px) {
    font-size: 10px;
  }
}





/* ####################################################################################################################
#######################################################################################################################

########   ######  ##     ## ########      #######  
##     ## ##    ## ##     ## ##           ##     ## 
##     ## ##       ##     ## ##                  ## 
########   ######  ##     ## #######       #######  
##     ##       ##  ##   ##        ##            ## 
##     ## ##    ##   ## ##   ##    ## ### ##     ## 
########   ######     ###     ######  ###  #######  

#######################################################################################################################
#####################################################################################################################*/

@import "./node_modules/bootstrap/scss/functions";

// Variable overrides

// options
$enable-negative-margins: true;  
$enable-rfs: false;
$enable-dark-mode: false;

// scss-docs-start color-variables
$red:     $rs-red;
// $orange:  $orange;
// $yellow:  $yellow;
// $green:   $green;
// $light:   #fff;
// scss-docs-end color-variables

// scss-docs-start theme-color-variables
$primary:       $rs-red;
$secondary:     $black;
// scss-docs-end theme-color-variables



// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark:      $primary;


// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4.5,
  7: $spacer * 6,
);
// scss-docs-end spacer-variables-maps


// body
$body-color: $black; 

// links
$link-color: $primary;
$link-hover-color: $black;

// Style p element.
$paragraph-margin-bottom:   2.35rem;

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px, /* additional */
  xxxxl: 1920px, /* additional */
  xxxxxl: 2560px /* additional */
);
// scss-docs-end grid-breakpoints

// scss-docs-start container-max-widths
$container-max-widths: (
  xs: 90%, /* additional */
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1480px,
  xxxxl: 1640px,
);
// scss-docs-end container-max-widths

$grid-gutter-width:           1.5rem; //24px

// scss-docs-start border-variables
$border-width:                2px !default;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius:               0;
$border-radius-sm:            0;
$border-radius-lg:            0;
// scss-docs-end border-radius-variables

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif:      $font-light;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-base:              1.25rem; // 20
$font-size-sm:                $font-size-base * .8;
$font-size-lg:                1.875rem; // 30

$line-height-base:            1.7;
$line-height-sm:              1.5;
$line-height-lg:              2.2;

$h1-font-size:                $font-size-base * 3.25; // 60
$h2-font-size:                $font-size-base * 3.25; // 65
$h3-font-size:                $font-size-base * 1.5; // 30
$h4-font-size:                $font-size-base * 1.25; // 25
$h5-font-size:                $font-size-base; // 20
$h6-font-size:                $font-size-base; // 20

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $font-size-sm
);
// scss-docs-end font-sizes

// scss-docs-start headings-variables
$headings-margin-bottom:      1.45rem;
$headings-font-family:        $font-bold;
$headings-font-weight:        400;
$headings-line-height:        1.33;
// scss-docs-end headings-variables

$legend-margin-bottom:        -1.25rem;
$legend-font-size:            1.5rem !default;


$hr-margin-y:                 $spacer * 3;


// scss-docs-start input-btn-variables
$input-btn-padding-y:         0.875rem;
$input-btn-padding-x:         1.5rem;

$input-btn-padding-y-sm:      .313rem;
$input-btn-padding-x-sm:      1rem;

$input-btn-padding-y-lg:      0.75rem;
$input-btn-padding-x-lg:      2rem;
// scss-docs-end input-btn-variables


// scss-docs-start btn-variables
$btn-color:                   #ffffff;
$btn-border-radius:40px;
$btn-border-width:2px;
$btn-font-family:$font-semibold;
$btn-box-shadow:none;
$btn-font-size:16px;

// scss-docs-end btn-variables


// scss-docs-start focus-ring-variables
$focus-ring-width:      .25rem !default;
$focus-ring-opacity:    .15;
$focus-ring-color:      rgba($black, $focus-ring-opacity);
$focus-ring-blur:       0 !default;
$focus-ring-box-shadow: 0 0 $focus-ring-blur $focus-ring-width $focus-ring-color !default;
// scss-docs-end focus-ring-variables


// Tables

// scss-docs-start table-variables
$table-cell-padding-x:        1rem;

$table-bg:                    transparent;

$table-striped-bg-factor:     .1;
$table-striped-bg: rgba($primary, $table-striped-bg-factor);

$table-striped-order:         even;
$table-striped-columns-order: odd;

$table-border-width:          0;

$table-bg-scale:              -80% !default;
// scss-docs-end table-variables

// scss-docs-start table-loop
$table-variants: (
  "primary":    shift-color($primary, $table-bg-scale),
  "secondary":  shift-color($secondary, $table-bg-scale),
  "light":      $light-grey,
  "dark":       $primary,
);
// scss-docs-end table-loop




// Forms

// scss-docs-start form-label-variables
$form-label-margin-bottom:              0.375rem;
$form-label-font-weight:                700;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
$input-border-color:                   $black;
$input-focus-border-color:             $black;
// scss-docs-end form-input-variables


// Navs
// scss-docs-start nav-variables
$nav-link-padding-y:                0.188rem;
$nav-link-padding-x:                $spacer * 2;
$nav-link-font-size:                $font-size-base * 0.8;
$nav-link-color:                    $body-color;
$nav-link-hover-color:              $body-color;
// scss-docs-end nav-variables


// Navbar
// scss-docs-start navbar-variables
$navbar-padding-y:                  0;
$navbar-padding-x:                  0;
$navbar-nav-link-padding-x:         0;

$navbar-light-color:                $body-color;
$navbar-light-hover-color:          $body-color;
$navbar-light-active-color:         $body-color;
$navbar-light-icon-color:           $primary;
$navbar-light-toggler-border-color: tansparent;
$navbar-toggler-focus-width:        0;
// scss-docs-end navbar-variables


// Dropdowns
// Dropdown menu container and contents.
// scss-docs-start dropdown-variables
$dropdown-padding-x:                0;
$dropdown-font-size:                $nav-link-font-size;
$dropdown-bg:                       $white;
$dropdown-border-width:             0;

//$dropdown-link-hover-bg:            $vh-lightgrey;

$dropdown-link-active-color:        $primary;
$dropdown-link-active-bg:           $white;

$dropdown-item-padding-y:           $spacer * .5;
$dropdown-item-padding-x:           $spacer * 1.5;
// scss-docs-end dropdown-variables



// Accordion

// scss-docs-start accordion-variables
$accordion-padding-y:                     0;
$accordion-padding-x:                     0;
// $accordion-color:                         var(--#{$prefix}body-color) !default;
$accordion-bg:                            transparent;
$accordion-border-width:                  0;
// $accordion-border-color:                  $black;
// $accordion-border-radius:                 var(--#{$prefix}border-radius) !default;
// $accordion-inner-border-radius:           subtract($accordion-border-radius, $accordion-border-width) !default;

$accordion-body-padding-y:                0.5rem;
// $accordion-body-padding-x:                $accordion-padding-x !default;

$accordion-button-padding-y:              $grid-gutter-width;
// $accordion-button-padding-x:              2rem!important;
// $accordion-button-color:                  var(--#{$prefix}body-color) !default;
$accordion-button-bg:                     transparent;
// $accordion-transition:                    $btn-transition, border-radius .15s ease !default;
$accordion-button-active-bg:              transparent;
$accordion-button-active-color:           $body-color;

$accordion-button-focus-border-color:     transparent;
$accordion-button-focus-box-shadow:       none;

$accordion-icon-width:                    1.75rem;
// $accordion-icon-color:                    $body-color !default;
$accordion-icon-active-color:             $body-color;
// $accordion-icon-transition:               transform .2s ease-in-out !default;
$accordion-icon-transform:                rotate(0deg);

$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='18.002' height='18.001' viewBox='0 0 18.002 18.001'><path id='Plus' data-name='Plus' d='M-20556.5-11114.8v-7.5h-7.5v-3h7.5v-7.5h3v7.5h7.5v3h-7.5v7.5Z' transform='translate(20564.002 11132.801)'/></svg>");
$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'><g id='Gruppe_490' data-name='Minus' transform='translate(-375 -2361.802)'><path id='Pfad_326' data-name='Pfad 326' d='M0,0H3V18H0Z' transform='translate(382.5 2361.802)' fill='rgba(0,0,0,0)'/><rect id='Rechteck_63' data-name='Rechteck 63' width='3' height='18' transform='translate(393 2369.302) rotate(90)'/></g></svg>");
// scss-docs-end accordion-variables



// Cards

// scss-docs-start card-variables
$card-spacer-y:                     $spacer * 1.5;
$card-spacer-x:                     $spacer * 1.5;
// $card-title-spacer-y:               $spacer * .5 !default;
// $card-title-color:                  null !default;
// $card-subtitle-color:               null !default;
$card-border-width:                 0;
// $card-border-color:                 var(--#{$prefix}border-color-translucent) !default;
// $card-border-radius:                var(--#{$prefix}border-radius) !default;
// $card-box-shadow:                   null !default;
// $card-inner-border-radius:          subtract($card-border-radius, $card-border-width) !default;
// $card-cap-padding-y:                $card-spacer-y * .5 !default;
// $card-cap-padding-x:                $card-spacer-x !default;
$card-cap-bg:                       transparent;
// $card-cap-color:                    null !default;
// $card-height:                       null !default;
// $card-color:                        null !default;
// $card-bg:                           var(--#{$prefix}body-bg) !default;
// $card-img-overlay-padding:          $spacer !default;
// $card-group-margin:                 $grid-gutter-width * .5 !default;
// scss-docs-end card-variables




// Breadcrumbs

// scss-docs-start breadcrumb-variables
$breadcrumb-font-size:              1rem;
$breadcrumb-padding-y:              1rem;
$breadcrumb-padding-x:              1.5rem;
$breadcrumb-margin-bottom:          0;
$breadcrumb-divider-color:          $body-color;
$breadcrumb-active-color:           $body-color;
$breadcrumb-divider:                quote("\e90b");
// scss-docs-end breadcrumb-variables


$zindex-dropdown: 100;
$zindex-sticky: 102;
$zindex-fixed: 103;
$zindex-modal-backdrop: 0 !important; // fix for ng-bootstrap 3.2.0 todo: remove if fixed
$zindex-modal: 105;


// Required
@import "./node_modules/bootstrap/scss/variables";
//@import "./node_modules/bootstrap/scss/variables-dark";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";
@import "./node_modules/bootstrap/scss/utilities";


// Optional

@import "./node_modules/bootstrap/scss/reboot";
@import "./node_modules/bootstrap/scss/type";
// @import "./node_modules/bootstrap/scss/images";
@import "./node_modules/bootstrap/scss/containers";
@import "./node_modules/bootstrap/scss/grid";
@import "./node_modules/bootstrap/scss/tables";
@import "./node_modules/bootstrap/scss/forms";
@import "./node_modules/bootstrap/scss/buttons";
@import "./node_modules/bootstrap/scss/transitions";
@import "./node_modules/bootstrap/scss/dropdown";
// @import "./node_modules/bootstrap/scss/button-group";
@import "./node_modules/bootstrap/scss/nav";
@import "./node_modules/bootstrap/scss/navbar";
@import "./node_modules/bootstrap/scss/card";
@import "./node_modules/bootstrap/scss/accordion";
@import "./node_modules/bootstrap/scss/breadcrumb";
// @import "./node_modules/bootstrap/scss/pagination";
// @import "./node_modules/bootstrap/scss/badge";
// @import "./node_modules/bootstrap/scss/alert";
// @import "./node_modules/bootstrap/scss/progress";
// @import "./node_modules/bootstrap/scss/list-group";
// @import "./node_modules/bootstrap/scss/close";
// @import "./node_modules/bootstrap/scss/toasts";
@import "./node_modules/bootstrap/scss/modal";
// @import "./node_modules/bootstrap/scss/tooltip";
// @import "./node_modules/bootstrap/scss/popover";
// @import "./node_modules/bootstrap/scss/carousel";
// @import "./node_modules/bootstrap/scss/spinners";
// @import "./node_modules/bootstrap/scss/offcanvas";
// @import "./node_modules/bootstrap/scss/placeholders";
@import "./node_modules/bootstrap/scss/helpers";

@import "./node_modules/bootstrap/scss/utilities/api";





/* ####################################################################################################################
#######################################################################################################################

##     ##  #######  
##     ## ##     ## 
##     ## ##     ## 
##     ## ##     ## 
##     ## ##     ## 
##     ## ##     ## 
 #######   #######  

#######################################################################################################################
#####################################################################################################################*/

//$main-padding: (($spacer * 6) - 0.75rem) 0 (($spacer * 6) - $paragraph-margin-bottom) 0;
//$main-padding: ($spacer * 6) - 0.75rem;

%main-h-padding {
  padding-top: ($grid-gutter-width * 2);
  padding-bottom:($grid-gutter-width * 2) - $paragraph-margin-bottom;

  @include media-breakpoint-up(md) {
    padding-top: ($grid-gutter-width * 3);
    padding-bottom:($grid-gutter-width * 3) - $paragraph-margin-bottom;
  }

  @include media-breakpoint-up(xxxl) {
    padding-top: ($grid-gutter-width * 4);
    padding-bottom:($grid-gutter-width * 4) - $paragraph-margin-bottom;
  }
}


// Breakpoints 
$bp-xs: map-get($grid-breakpoints, xs);       //0
$bp-sm: map-get($grid-breakpoints, sm);       //576px
$bp-md: map-get($grid-breakpoints, md);       //768px
$bp-lg: map-get($grid-breakpoints, lg);       //992px
$bp-xl: map-get($grid-breakpoints, xl);       //1200px
$bp-xxl: map-get($grid-breakpoints, xxl);     //1400px
$bp-xxxl: map-get($grid-breakpoints, xxxl);   //1600px
$bp-xxxxl: map-get($grid-breakpoints, xxxxl); //1920px
$bp-xxxxxl: map-get($grid-breakpoints, xxxxxl); //2560px



// global
@import "./global.scss";

// modules
@import "./modules.scss";
