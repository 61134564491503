.event-slider-wrapper {
	.swiper-pagination-events.swiper-pagination-bullets.swiper-pagination-horizontal {
		position:relative;
		margin:0 auto;
		padding:6px 20px 10px 20px;
		background:#ececec;
		border-radius:100px;
		top:inherit;
		right:inherit;
		left:50%;
		transform:translateX(-50%);
		bottom:inherit;
		display:inline-block;
		width:auto;
	}

	.swiper-pagination-bullet {
		width:10px;
		height:10px;
		opacity:0.35;
		border-radius:100%;
		margin:var(--swiper-pagination-bullet-vertical-gap, 12px) 0;

		&:hover {
			opacity:.7;
		}

		&.swiper-pagination-bullet-active {
			background:$rs-red;
			opacity:1;
		}
	}
}

.event-slider-container {
	position:relative;
	padding:19px 0 0 0;
	
	@include media-breakpoint-up(xl) { 
		width:calc(50% + 50vw); 
		margin-left:calc(50% - 50%vw);
	}
}

.event-slider.swiper {
	position:relative;
	overflow:visible;
	
	@include media-breakpoint-up(xl) {
		width:100vw;
	}

	.swiper-slide {
		height:auto;
		padding:40px 0;
	}
}

.event-teaser {
	display:flex;
	flex-direction:column;
	background:$white;
	border-radius:20px;
	box-shadow:1px 1px 51px rgba(0,0,0,.14);
	height:100%;

	.event-teaser-image {
		img {
			border-radius:20px 20px 0 0;
		}
	}

	.event-teaser-text {
		padding:0 40px 32px 40px;
		
		h3 {
			font-size:25px; line-height:1.2em; text-transform:uppercase; margin:0; padding:0 0 6px 0;
		}

		p {
			font-size:18px; line-height:1.4em; margin:0; padding:0; hyphens:auto;
		}

		span {
			font-size:1rem; color:rgba($black,.5);
		}
	}
	
	.btn {
		margin:auto 30px 0 30px; display:block;
	}

	a {
		display:flex;
		flex-direction:column;
		height:100%;
		text-decoration:none;
		color:$black;

		&:hover {
			// .btn {
			//     background:$black;
			//     border-color:$black;
			// }

			h3 {
				color:$rs-red;
			}
		}
	}

	@include media-breakpoint-up(md) { 
		height:100%;
	}
}
