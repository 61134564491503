
section.hero-image {
	position:relative;
	margin:0 auto;
	width:100%;
	aspect-ratio:2.13;
	max-height:900px;
	overflow:hidden;

	@include media-breakpoint-down(lg) { 
		aspect-ratio:0.91;
	}

	.hero-image-container {
		position:relative;

		.container {
			height:100%;
		}

		img {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			object-fit:cover;
		}

		.image-caption {
			position:relative;
			z-index:2;
			padding:0 0 5% 0;
			color:$white;
			text-align:center;

			&:before {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				content:"";
				z-index:0;
				background:linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
			}

			h1 {
				font-size:5rem;
				color:$white;
				line-height:1.1em;
				margin:0;
				padding:0 0 10px 0;
			}

			p {
				font-size:2.188rem; line-height:1.4em; font-family:$font-light; max-width:50%; margin:0 auto; padding:0;
			}
	
			span.tag {
				font-size:18px; font-family:$font-semibold; display:block;
			}
		}
	}
}