section.app-download {
    padding-bottom:0;
    .app-download-text {
        padding:80px 0 0 0;
    }

    .app-download-screen {
        position:relative;
        transform:translateY(-7%);
    }
    
    .btn-store {
        margin-right:15px;

        img {
            max-width:172px;
        }
    }
}