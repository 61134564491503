.product-slider-wrapper {
	background: linear-gradient(transparent 30%, #f1f1f1 30%) no-repeat;
}

.product-slider-container {
	position:relative;
	max-width:1760px;
	margin:0 auto;
	padding:100px 0;	
}

.product-slider.swiper {
	position:relative;
	max-width:1760px;
	margin:0 20px;
	aspect-ratio:1.99;
	max-height:882px;
	border-radius:20px;
	box-shadow: rgba(17, 17, 26, 0.2) 0px 8px 24px, rgba(17, 17, 26, 0.2) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;

	.swiper-slide {
		position:relative;
		height:100%;
		margin:0;
		padding:0;

		.slide-caption-container {
			position:absolute;
			width:100%;
			height:100%;
			z-index:3;

			.slide-caption {
				position:relative;

				svg {
					width:100%;
					height:auto;
				}
			}
		}
	}

	.prodcut-image {
		position:relative;

		img {
			object-fit:cover;
			aspect-ratio:1.99;
		}

		&:before {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			background: transparent linear-gradient(80deg, #000000 0%, #545454 100%) 0% 0% no-repeat padding-box;
			mix-blend-mode: multiply;
			border-radius:20px;
			opacity: 0.6;
			z-index:2;
			content:"";
		}
	}

	.product-signet {
		padding:0 40px;
	}

	.product-text {
		padding:170px 60px 0 20px;

		h2 {
			color:$white; margin:0; padding:0 0 17px 0;
		}

		p {
			font-size:24px; color:$white; line-height:1.6em;
		}

		.btn {
			margin-right:10px;
		}
	}

	.swiper-pagination {
		margin:0;
		padding:13px 20px;
		background:rgba($white,.25);
		border-radius:100px;
		backdrop-filter:blur(26px);
		top:50%;
		right:40px;
		transform:translateY(-50%);
	}

	.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
		background:$white;
		width:10px;
		height:10px;
		opacity:0.35;
		border-radius:100%;
		margin:var(--swiper-pagination-bullet-vertical-gap, 12px) 0;
		display:block;

		&:hover {
			opacity:.7;
		}

		&.swiper-pagination-bullet-active {
			opacity: 1;
		}
	}
}

@media print { 
	.hero-slider.swiper {
		display: none!important;
	}
}


@mixin sliderSkin($skin, $background, $color) {
	.btn.btn-primary {
		border-color:$background;
		background-color:$background;
		color:$color;
	
		&:hover {
			background-color:$color;
			color:$background;
			border-color:$color;
		}
	}

	.btn.btn-outline-secondary {
		color:$color;
		border-color:$color;

		&:hover {
			color:$background;
			background-color:$color;
		}
	}
}

.swiper-slide {
	&.red {
		@include sliderSkin("red", $rs-red, $white);
	}

	&.purple {
		@include sliderSkin("purple", $rs-purple, $white);
	}

	&.orange {
		@include sliderSkin("orange", $rs-orange, $white);
	}

	&.blue {
		@include sliderSkin("blue", $rs-blue, $white);
	}

	&.green {
		@include sliderSkin("green", $rs-green, $white);
	}

	&.pink {
		@include sliderSkin("pink", $rs-pink, $white);
	}
}

