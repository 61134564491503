@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?qd7xy0');
  src:  url('fonts/icomoon.eot?qd7xy0#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?qd7xy0') format('truetype'),
    url('fonts/icomoon.woff?qd7xy0') format('woff'),
    url('fonts/icomoon.svg?qd7xy0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-faq:before {
  content: "\e908";
}
.icon-user:before {
  content: "\e909";
}
.icon-search:before {
  content: "\e90a";
}
.icon-basket:before {
  content: "\e90b";
}
.icon-aktivitaet:before {
  content: "\e904";
}
.icon-schadensregulierung:before {
  content: "\e905";
}
.icon-sorglos-laufen:before {
  content: "\e906";
}
.icon-wechselservice:before {
  content: "\e907";
}
.icon-exercise:before {
  content: "\e901";
}
.icon-level-bars:before {
  content: "\e903";
}
.icon-time:before {
  content: "\e900";
}
.icon-level:before {
  content: "\e902";
}
