section.workouts {
    &.detail {
        .kicker {
            h3 {
                padding-bottom:60px;
            }
        }
    }
    .filter-workouts {
        padding:58px 0 10px 0;

        ul {
            list-style-type:none;
            padding:0;

            li {
                margin:0;

                .btn {
                    &.active {
                        background:$rs-red;
                        color:$white;
                        border-color:$rs-red;
                    }
                }
            }
        }
    }

    .workout-teaser-container {
        [class*='col-'] {
            margin:0 0 40px 0;
        }
    }
    
    .workout-teaser {
        display:flex;
        flex-direction:column;
        background:$white;
        border-radius:20px;
        padding-bottom:40px;
        box-shadow:1px 1px 51px rgba(0,0,0,.14);

        .workout-teaser-image {
            img {
                border-radius:20px 20px 0 0;
            }
        }

        .workout-teaser-text {
            padding:29px 50px 0 30px;

            h3 {
                line-height:1.2em; margin:0; padding:0 0 6px 0; min-height:77px; @include ease;
            }

            p {
                font-size:18px; line-height:1.4em; margin:0; padding:0; hyphens:auto;
            }
        }
        
        .btn {
            margin:auto 30px 0 30px; display:block;
        }

        a {
            display:flex;
            flex-direction:column;
            height:100%;
            text-decoration:none;
            color:$black;

            &:hover {
                // .btn {
                //     background:$black;
                //     border-color:$black;
                // }

                h3 {
                    color:$rs-red;
                }
            }
        }

        @include media-breakpoint-up(md) { 
            height:100%;
        }
    }
}

.workout-teaser-parameters {
    margin-top:auto;
    padding:20px 20px 27px 20px;

    ul {
        list-style-type:none;
        margin:0;
        padding:0;
        
        li {
            position:relative;
            margin:0 !important;
            font-size:14px;
            font-family:$font-semibold;
            border-left:1px solid $dark-grey;
            text-align:center;

            &:first-child {
                border:0;
            }
            
            span {
                font-family:$font-semibold;
                display:block;
                color:rgba($black,.5);
                line-height:1.2em;
                display:block;
            }

            span + span {
                color:$black;
            }

            &:before {
                font-family:$font-icon;
                font-size:25px;
                top:0;
                line-height:1.2em;
            }

            &.time {
                &:before {
                    content:"\e900";
                }
            }

            &.exercise {
                &:before {
                    content:"\e901";
                }
            }

            &.level {
                &:before {
                    content:"\e903";
                }
            }
        }
    }
}