section.ki-assistent {
    padding-bottom:100px;
    
    .ki-assistent-text {
        max-width:90%;
    }
}

.tags {
    ul {
        list-style:none;
        margin:0;
        padding:0;

        li {
            display:inline-block;
            margin:0 14px 20px 0;
            padding:0;
        }
    }
}