/* ####################################################################################################################
#######################################################################################################################

 ######  ########  ######  ######## ####  #######  ##    ##  ######  
##    ## ##       ##    ##    ##     ##  ##     ## ###   ## ##    ## 
##       ##       ##          ##     ##  ##     ## ####  ## ##       
 ######  ######   ##          ##     ##  ##     ## ## ## ##  ######  
      ## ##       ##          ##     ##  ##     ## ##  ####       ## 
##    ## ##       ##    ##    ##     ##  ##     ## ##   ### ##    ## 
 ######  ########  ######     ##    ####  #######  ##    ##  ######  

#######################################################################################################################
#####################################################################################################################*/


section {
	@extend %main-h-padding;
	overflow: hidden;
}

.bg-white {
	background-color: $white;
}

@mixin sectionColors($background, $color, $contrast, $error) {
	background-color: $background;

	*:not(.btn, a:hover) {
		color: $color;
	}

	a {
		font-family: $font-medium;
	}

	ul.content li:before {
		background-color: $color;
	}

	thead th {
		background-color: $color;
		color: $contrast!important;
	}

	.table-striped > tbody > tr:nth-of-type(2n) > * {
		background-color: darken($background, 3%);
	}

	.invalid-feedback {
		font-family: $font-medium;
		color:$error!important;
	}

	.form-check-input {
		border-color: $color;
	 
		&:checked {
		background-color: $color;
		}		
	}

	.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
		border-color: $error;		
	}	 
}

section.bg-green {
	@include sectionColors($rs-red, $black, $white, $red-800); 
}

section.bg-orange {
	@include sectionColors($rs-red, $black, $white, $red-700);
}

section.bg-yellow {
	@include sectionColors($rs-red, $black, $white, $red-600);
}

section.bg-lightgreen {
	@include sectionColors($rs-red, $black, $white, $red-600);
}

section.bg-lightgrey {
	background-color: $light-grey;
}


section.bg-white + section.bg-white,
section.bg-green + section.bg-green,
section.bg-orange + section.bg-orange,
section.bg-yellow + section.bg-yellow,
section.bg-lightgreen + section.bg-lightgreen,
section.bg-lightgrey + section.bg-lightgrey {
	padding-top: 0;
}

.cols-2-container, .cols-3-container {
	.col-sm-10.offset-sm-1.col-md-8.offset-md-2 {
		margin-left: 0;
		width: 100%;
	}
}

.container .container {
	padding-left: 0;
	padding-right: 0;
	max-width: 100%;
}

@media print {
	section {
		padding-top: ($grid-gutter-width)!important;
    	padding-bottom:($grid-gutter-width) - $paragraph-margin-bottom!important;
	}
}