/* ####################################################################################################################
#######################################################################################################################

##     ## ######## ########   #######           ######  ##       #### ########  ######## ########  
##     ## ##       ##     ## ##     ##         ##    ## ##        ##  ##     ## ##       ##     ## 
##     ## ##       ##     ## ##     ##         ##       ##        ##  ##     ## ##       ##     ## 
######### ######   ########  ##     ## #######  ######  ##        ##  ##     ## ######   ########  
##     ## ##       ##   ##   ##     ##               ## ##        ##  ##     ## ##       ##   ##   
##     ## ##       ##    ##  ##     ##         ##    ## ##        ##  ##     ## ##       ##    ##  
##     ## ######## ##     ##  #######           ######  ######## #### ########  ######## ##     ##  

#######################################################################################################################
#####################################################################################################################*/





.hero-slider.swiper {
	position: relative;
	overflow-y: visible;
	height:100vh;

	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	
	.swiper-slide {
		height:100%;

		.slide-caption-container {
			position:absolute;
			width:100%;
			height:100%;
			z-index:2;

			.slide-caption {
				position:relative;
				padding:0 0 200px 0;

				h2 {
					font-size:5rem;
					color:$white;
					line-height:1.1em;
				}
			}
		}

		.btn {
			margin:21px 15px 0 0;

			&:hover {
				background:$white;
				color:$rs-red;
				border-color:$white;
			}
		
			&.btn-outline-secondary {
				color:$white;
				border-color:$white;
				border:2px solid r$white;
		
				&:hover {
					background:$white;
					color:$rs-red;
					border-color:$white;
				}
			}
		}
	}

	&:hover {
		@include media-breakpoint-up(lg) {
			.swiper-button-next, 
			.swiper-button-prev {
				opacity: 1;
			}
		}
	}
	
	.hero-caption {
		position: absolute;
		z-index: 3;
		top: auto;
		right: 0;
		left: 0;
		bottom: $spacer * 3;
		padding:0;	

		@include media-breakpoint-up(md) {
			bottom: $spacer * 6;
		}

		@include media-breakpoint-up(xl) {
			bottom: $spacer * 7.5;
		}

		@include media-breakpoint-up(xxxxl) {
			bottom: $spacer * 12;
		}

		*:not(.btn) {
			color: #fff;
		}		

		.h1 {
			hyphens: auto;
			font-size: $font-size-base * 2;

			@include media-breakpoint-up(sm) {
				font-size: $font-size-base * 3;	
			}

			@include media-breakpoint-up(xl) {
				font-size: $font-size-base * 4;
			}
		}

		.btn {
			//margin-bottom: calc($paragraph-margin-bottom / 2);

			@media (max-width:$bp-md) {
				display: block;
				margin-right: 0;
			}
		}
				
		> .container {
			position: relative;
		}
	} 

	.swiper-pagination {
		bottom: 0;
		padding-top: 2rem;
		padding-bottom: 1rem;
		background: rgb(0,0,0);
		background: -moz-linear-gradient(bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
		background: -webkit-linear-gradient(bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
		background: linear-gradient(bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
	}

	.swiper-pagination-bullet {
		background-color: $white;
		border-radius: 0;
		width: 12px;
		height: 12px;
		opacity: 0.5;

		&.swiper-pagination-bullet-active {
			opacity: 1;
		}
	}

	.swiper-button-next, 
	.swiper-button-prev {
		color: $white;
		opacity: 0;
		@include ease;
	}

}

@media print { 
	.hero-slider.swiper {
		display: none!important;
	}
}