/* poppins-200 - latin */
@font-face {
	font-display: swap;
	font-family: 'poppins extralight';
	font-style: normal;
	font-weight: 200;
	src: url('poppins-v20-latin/poppins-v20-latin-200.woff2') format('woff2');
  }
  /* poppins-300 - latin */
  @font-face {
	font-display: swap;
	font-family: 'poppins light';
	font-style: normal;
	font-weight: 300;
	src: url('poppins-v20-latin/poppins-v20-latin-300.woff2') format('woff2');
  }
  /* poppins-regular - latin */
  @font-face {
	font-display: swap;
	font-family: 'poppins book';
	font-style: normal;
	font-weight: 400;
	src: url('poppins-v20-latin/poppins-v20-latin-regular.woff2') format('woff2');
  }
  /* poppins-500 - latin */
  @font-face {
	font-display: swap;
	font-family: 'poppins medium';
	font-style: normal;
	font-weight: 500;
	src: url('poppins-v20-latin/poppins-v20-latin-500.woff2') format('woff2');
  }
  /* poppins-600 - latin */
  @font-face {
	font-display: swap;
	font-family: 'poppins semibold';
	font-style: normal;
	font-weight: 600;
	src: url('poppins-v20-latin/poppins-v20-latin-600.woff2') format('woff2');
  }
  /* poppins-700 - latin */
  @font-face {
	font-display: swap;
	font-family: 'poppins bold';
	font-style: normal;
	font-weight: 700;
	src: url('poppins-v20-latin/poppins-v20-latin-700.woff2') format('woff2');
  }
  /* poppins-800 - latin */
  @font-face {
	font-display: swap;
	font-family: 'poppins extrabold';
	font-style: normal;
	font-weight: 800;
	src: url('poppins-v20-latin/poppins-v20-latin-800.woff2') format('woff2');
  }
  /* poppins-900 - latin */
  @font-face {
	font-display: swap;
	font-family: 'poppins black';
	font-style: normal;
	font-weight: 900;
	src: url('poppins-v20-latin/poppins-v20-latin-900.woff2') format('woff2');
  }