section.blog-detail {
    .blog-video {
        padding:40px 0 70px 0;

        .video-container {
            position:relative;
            padding-bottom:56.25%;
            height:0;
        }

        .video-container iframe {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
        }
    }

    .blog-detail-text {
        ul {
            li {
                padding-bottom:20px;
            }
        }
    }

    .workout-teaser-parameters {
        margin-top:-40px;
        padding:0 20px 60px 20px;
    
        ul {
            list-style-type:none;
            margin:0;
            padding:0;
            
            li {
                position:relative;
                margin:0 !important;
                font-size:18px;
                font-family:$font-semibold;
                border-left:1px solid $dark-grey;
                text-align:center;
    
                &:first-child {
                    border:0;
                }
                
                span {
                    font-family:$font-semibold;
                    display:block;
                    color:rgba($black,.5);
                    line-height:1.2em;
                    display:block;
                }
    
                span + span {
                    color:$black;
                }
    
                &:before {
                    font-family:$font-icon;
                    font-size:40px;
                    top:0;
                    color:$black;
                    line-height:1.6em;
                }
    
                &.time {
                    &:before {
                        content:"\e900";
                    }
                }
    
                &.exercise {
                    &:before {
                        content:"\e901";
                    }
                }
    
                &.level {
                    &:before {
                        content:"\e903";
                    }
                }
            }
        }
    }
}