section.gamification {
    .path {
        margin:60px 0 0 0;
        background-size:cover;
        background-position:center;
        background-repeat:no-repeat;
        aspect-ratio:3.33;

        .badge {
            max-width:400px;
            font-family:$font-bold;
            color:$white;
            margin:0 auto;
            padding:28px 40px 28px 40px;
            border-radius:20px;
            background:#333;
            box-shadow:rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    
            &.red {
                background:$rs-red;
            }
    
            svg,
            img {
                fill:$white;
                width:auto;
                height:70px;
            }
    
            span {
                font-size:3.438rem; padding-left:10px;
            }
    
            p {
                font-size:1.375rem; line-height:1.4em; text-transform:uppercase; margin:0; padding:22px 0 0 0;
            }
        }
    }

    .gamification-text {
        max-width:980px;
        margin:-4% auto 0 auto;
        text-align:center;

        span {
			color:$rs-red;
		}

        .btn {
            margin:0 5px;
        }
    }

    .gamification-image {
        position:relative;
        overflow:hidden;
        max-width:1920px;
        margin:0 auto;

        img {
            object-fit:cover;
            display:block;
            margin:0 auto;
        }
    }
}